<template>
    <div class="bkColor">
        <headTitle :title="title" :routerPath="routerPath"></headTitle>
        <van-search v-model="value" input-align="center" placeholder="请输入搜索关键词" shape="round" show-action @search="onSearch"
            @cancel="onCancel" style="margin-bottom: 2vh" />
        <div class="search">
            <div>当前年份:</div>
            <div style="
            display: flex;
            justify-content: space-around;
            align-items: center">
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.currentYear" :options="yearOption" @change="changeYear" />
                </van-dropdown-menu>
                <div class="searchTitle">是否归还:</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.notReturn" :options="notReturnOption" @change="changePjState" />
                </van-dropdown-menu>
                <div class="searchTitle">归属:</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.belong" :options="belongOption" @change="changeBelong" />
                </van-dropdown-menu>
            </div>
        </div>
        <div class="list">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
                    finished-text="没有更多了" @load="getExpenditure">
                    <div class="listCell" v-for="item in list" :key="item.id" @mouseup="up($event)"
                        @mousedown="down($event)" @click="goDetails(item.id)">

                        <div class="cellName">
                            {{
                                item.projectName
                                ? item.projectName + "保证金付款"
                                : item.userName + "的" + item.paymentName + "付款"
                            }}
                            <div class="status">
                                <span class="green" v-if="item.relateNo">已归还</span>
                                <span class="red" v-else>未归还</span>
                            </div>
                        </div>
                        <div class="cellText">流水号：{{ item.paymentNo }}</div>
                        <div class="cellText">收入流水号：{{ item.relateNo ? item.relateNo : '暂未归还保证金' }}</div>
                        <div class="cellText">
                            付款说明：
                            <div style="width: 40%; display: inline-block; vertical-align: top">
                                {{ item.description }}
                            </div>
                        </div>
                        <div class="cellText">付款金额：{{ item.price }}元</div>
                        <div class="cellText">付款日期：{{ item.paymentTime }}</div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
        <!-- <div class="addBtn">
            <img src="../assets/icon/xz_icon.png" alt="" @click="add" />
        </div> -->
        <div class="exportBtn" @click="exportFile">导出</div>
        <!-- <div class="importBtn" @click="importFile">导入</div> -->
    </div>
</template>
    
<script>
import headTitle from "../components/headTitle.vue";
import Vue from "vue";
import {
    Search,
    Toast,
    List,
    PullRefresh,
    Cell,
    SwipeCell,
    Button,
    Dialog,
} from "vant";

import { settlementText, yearOption } from "../units/units";
import { expenditure, expenditureExport } from "../api/api";

import { getToken } from "../units/auth";

Vue.use(Search);
Vue.use(Toast);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Cell);
Vue.use(SwipeCell);
Vue.use(Button);
Vue.use(Dialog);
export default {
    name: "depositExpenditure",
    components: {
        headTitle,
    },
    data() {
        return {
            title: "保证金支出",
            yearOption: yearOption,
            notReturnOption: [
                { text: "全部", value: null },
                { text: "未归还", value: 0 },
                { text: "已归还", value: 1 },
            ],
            // isSettleOption: [
            //     { text: "全部", value: null },
            //     { text: "未支付", value: 0 },
            //     { text: "已支付", value: 1 },
            //     { text: "拒绝支付", value: 2 },
            // ],
            belongOption: [
                { text: "全部", value: null },
                { text: "大美", value: "1" },
                { text: "中创", value: "2" },
                { text: "广云仓", value: '3' },
                { text: "大丰年", value: '4' },
            ],
            value: "",
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
            error: false,
            queryFrom: {
                currentYear: null,
                notReturn: null,
                vague: "",
                belong: null,
                pageSize: 10,
                pageNum: 1,
                // additional: 0,
                // isSettle: null,
            },
            powerList: [], //value 1总经理 2财务 3行政
            userId: "",
            routerPath: '/',
            equipment: false
        };
    },
    mounted() {
        if (this.$route.query.routerPath) {
            this.routerPath = this.$route.query.routerPath
            console.log(this.routerPath)
        }
        this.queryFrom.currentYear = this.$route.query.year * 1 || null
        this.queryFrom.belong = this.$route.query.belong > 0 ? this.$route.query.belong : null
        this.getUserId();
        this.getPowerList();
    },
    methods: {
        //获取权限渲染页面
        getPowerList() {
            this.powerList = JSON.parse(getToken("powerList") || "[]");
        },
        getUserId() {
            this.userId = getToken("userId");
        },
        getExpenditure() {
            this.loading = true;
            expenditure(this.queryFrom).then((res) => {
                this.list =
                    this.queryFrom.pageNum == 1
                        ? res.data.rows
                        : this.list.concat(res.data.rows);
                this.refreshing = false;
                this.loading = false;
                this.queryFrom.pageNum = this.queryFrom.pageNum + 1;
                if (
                    res.data.total <=
                    (this.queryFrom.pageNum - 1) * this.queryFrom.pageSize
                ) {
                    this.finished = true;
                }
            });
        },
        //财务是否结算
        settlement(f) {
            return settlementText(f);
        },
        stateText(t) {
            console.log(t)
            return
        },
        changeYear(val) {
            this.queryFrom.currentYear = val;
            this.onRefresh();
        },
        changePjState(val) {
            this.queryFrom.notReturn = val;
            this.onRefresh();
        },
        changeIsSettle(val) {
            this.queryFrom.isSettleOption = val;
            this.onRefresh();
        },
        changeBelong(val) {
            this.queryFrom.belong = val;
            this.onRefresh();
        },
        onSearch(val) {
            console.log(val);
            this.queryFrom.vague = val;
            this.onRefresh();
        },
        //搜索框取消
        onCancel() {
            this.queryFrom.vague = "";
            // this.queryFrom.state = null;
            // this.queryFrom.isSettle = null;
            this.queryFrom.belong = null;
            this.onRefresh();
        },
        //下拉刷新
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.queryFrom.pageNum = 1;
            this.list = [];
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.getExpenditure();
        },
        // add() {
        //     this.$router.push({
        //         path: "/newPjPayment",
        //     });
        // },
        // exportFile() {
        //     paymentExport(this.queryFrom).then((res) => {
        //         var blob = new Blob([res.data], {
        //             type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        //         });
        //         if ("download" in document.createElement("a")) {
        //             // 非IE下载
        //             var elink = document.createElement("a");
        //             elink.download = "付款列表";
        //             elink.style.display = "none";
        //             elink.href = URL.createObjectURL(blob);
        //             document.body.appendChild(elink);
        //             elink.click();
        //             URL.revokeObjectURL(elink.href); // 释放URL 对象
        //             document.body.removeChild(elink);
        //         } else {
        //             // IE10+下载
        //             navigator.msSaveBlob(blob, "付款列表");
        //         }
        //     });
        // },
        // importFile() {
        //     this.$router.push({
        //         path: "/fileImport",
        //         query: { title: "付款", routerPath: "/pjPayment" },
        //     });
        // },
        //财务最后确认
        // finalConfirmation(id, val) {
        //     Dialog.confirm({
        //         message: `是否${val == 1 ? "确认" : "拒绝"}该报销`,
        //         confirmButtonColor: "#0092fe",
        //     }).then(() => {
        //         paymentUpdateState(id, val).then((res) => {
        //             if (res.data.code == 200) {
        //                 Toast.success("完成操作");
        //                 this.onRefresh();
        //             }
        //         });
        //     });
        // },
        goDetails(id) {
            this.$router.push({
                path: "/paymentDetails",
                query: { id: id, route: '/depositExpenditure' },
            });
        },
        exportFile() {
            if (!this.equipment) {
                Toast('此功能目前只能电脑端使用！')
                return
            }
            expenditureExport(this.queryFrom).then((res) => {
                var blob = new Blob([res.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                if ("download" in document.createElement("a")) {
                    // 非IE下载
                    var elink = document.createElement("a");
                    elink.download = "保证金收入列表";
                    elink.style.display = "none";
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放URL 对象
                    document.body.removeChild(elink);
                } else {
                    // IE10+下载
                    navigator.msSaveBlob(blob, "保证金收入列表");
                }
            });
        },
        isMobile() {
            this.equipment = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? false : true
        }
    },
};
</script>
    
<style lang="less" scoped>
.blue {
    color: #1989fa;
}

.red {
    color: #e86f61;
}

.green {
    color: #00ab45;
}

.bkColor {
    width: 100vw;
    min-height: 100vh;
    background: #f6f6f6;
    position: relative;
}

.search {
    width: 100vw;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    background: #fff;
    font-size: 13px;
    line-height: 8vh;
}

.searchTitle {
    margin-left: 2vw;
}

/deep/ .van-dropdown-menu__title {
    padding: 0 8px 0 0;
}

/deep/ .van-ellipsis {
    font-size: 13px;
}

.list {
    margin-top: 2vh;
}

.listCell {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 5vw 1vh;
    background: #fff;
    text-align: left;
}

.cellName {
    width: 90%;
    font-size: 1rem;
    font-weight: 600;
    border-top: 2px solid #f6f6f6;
    padding-top: 1vh;
    padding-bottom: 1vh;

    .status {
        float: right;
        font-size: 0.8rem;
        font-weight: 500;
        text-align: right;
    }
}

/deep/ .van-list :first-of-type.listCell .cellName {
    border-top: unset;
}

.cellText {
    width: 70%;
    color: #797979;
    margin-bottom: 0.5vh;
    font-size: 0.7rem;
    word-break: break-all;
    line-height: 1;
}

// .addBtn {
//     width: 70px;
//     height: 70px;
//     position: fixed;
//     top: 77%;
//     left: 77%;

//     img {
//         width: 70px;
//         height: 70px;
//     }
// }

.exportBtn {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 77%;
    left: 77.1%;
    background: #3495fc;
    border-radius: 50%;
    line-height: 50px;
    color: #fff;
    box-shadow: 0px 6px 8px 0px rgba(122, 117, 117, 0.39);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

// .importBtn {
//     width: 65px;
//     height: 65px;
//     position: fixed;
//     top: 66%;
//     left: 77.1%;
//     background: #3495fc;
//     border-radius: 50%;
//     line-height: 65px;
//     color: #fff;
//     box-shadow: 0px 6px 8px 0px rgba(122, 117, 117, 0.39);
// }

/deep/ .van-button {
    height: 100%;
}
</style>